function woops() {
    var closeInSeconds = 10;
    var displayText = "Trying #1 seconds.";
    var timer;
    swal({
        title: trans("Can not connect to the server!"),
        text: displayText.replace(/#1/, closeInSeconds),
        timer: closeInSeconds * 1000,
        buttons: {
            confirm: {
                text: 'Try Now!',
                value: true,
                visible: true,
                className: "",
                closeModal: true
            }
        }
    }).then(() => {
        dataActive.pageReload();
    });
    timer = setInterval(function () {

        closeInSeconds--;

        if (closeInSeconds < 0) {

            clearInterval(timer);
        }
        $('.swal-text').text(displayText.replace(/#1/, closeInSeconds));

    }, 1000);
    window.stop();
}
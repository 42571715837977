function ChooseCalendar(element, fliterButton, dateRange) {
    if (ChooseCalendar.calendar == undefined) {
        throw new Error('calendar Not config for date format');
    }

    var calendar = ChooseCalendar.calendar;
    if (calendar == 'jalali') {
        new GregorianAndJalaliCalendar(element, { beforeShow: () => { if ($(window).width() < 992) { fliterButton.hide() } }, afterShow: () => { if ($(window).width() < 992) { fliterButton.show() } }, dateRange: dateRange, calendarType: calendar });
    } else if (calendar == 'gregorian') {
        new CustomDatepicker(element, { beforeShow: () => { if ($(window).width() < 992) { fliterButton.hide() } }, afterShow: () => { if ($(window).width() < 992) { fliterButton.show() } }, dateRange: dateRange, calendarType: calendar });
    }
}
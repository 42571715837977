function GregorianAndJalaliCalendar(selector, config) {
    var $element = $(selector);
    var me = this;
    switch ($element.length) {
        case 0:
            return;
        case 1:
            break;
        default:
            $element.each((index, item) => {
                new GregorianAndJalaliCalendar(item, config);
            });
            return;
    }
    config = config || {};
    var currentCalendar = config.calendarType;
    var calendarWrapper = $(`<div class="gj-calendar-wrapper"><div class="gregoriancalendar"></div><div class="jalalicalendar"></div><div class="btn datepicker-footer"><button class="change-calendar-btn" type="button">${(currentCalendar == 'jalali') ? trans('Gregorian Calendar') : trans('jalali Calendar')}</button></div></div>`);
    var calendarContainer = $element.parent();
    $element.after(calendarWrapper);
    var startDate = calendarContainer.closest('.end-date-container').prev().find('.start-date');
    var endDate = calendarContainer.closest('.start-date-container').next().find('.end-date');


    calendarWrapper.find('.change-calendar-btn').click(function () {
        if (getConfig("currentCalendar", "gregorian") == 'jalali') {
            calendarWrapper.find('.jalalicalendar').hide();
            calendarWrapper.find('.gregoriancalendar').show();
            dataActive.updateConfig('currentCalendar', 'gregorian');
            $(this).text(trans('jalali Calendar'));
            var selectedate = ($element.val()).split('-');
            $element.val(dayjs((new persianDate([parseInt(selectedate[0]), parseInt(selectedate[1]), parseInt(selectedate[2])])).toDate()).format("YYYY-MM-DD"));
            if ($element.hasClass('end-date') && startDate.val()) {
                dateSelectedToStart = (startDate.val()).split('-');
                startDate.val(dayjs((new persianDate([parseInt(dateSelectedToStart[0]), parseInt(dateSelectedToStart[1]), parseInt(dateSelectedToStart[2])])).toDate()).format("YYYY-MM-DD"));
            }
            if ($element.hasClass('start-date') && endDate.val() != '') {
                dateSelectedToEnd = (endDate.val()).split('-');
                endDate.val(dayjs((new persianDate([parseInt(dateSelectedToEnd[0]), parseInt(dateSelectedToEnd[1]), parseInt(dateSelectedToEnd[2])])).toDate()).format("YYYY-MM-DD"));
            }
        } else {
            calendarWrapper.find('.gregoriancalendar').hide();
            calendarWrapper.find('.jalalicalendar').show();
            dataActive.updateConfig('currentCalendar', 'jalali');
            $(this).text(trans('Gregorian Calendar'));
            var selectedate = (new persianDate(new Date($element.val())));
            $element.val(selectedate.toLocale('en').format("YYYY-MM-DD"));
            if ($element.hasClass('end-date') && startDate.val()) {
                dateSelectedToStart = (new persianDate(new Date(startDate.val())));
                startDate.val(dateSelectedToStart.toLocale('en').format("YYYY-MM-DD"));
            }
            if ($element.hasClass('start-date') && endDate.val() != '') {
                dateSelectedToEnd = (new persianDate(new Date(endDate.val())));
                endDate.val(dateSelectedToEnd.toLocale('en').format("YYYY-MM-DD"));
            }
        }
        calendarWrapper.find('.' + getConfig("currentCalendar", "gregorian") + 'calendar').show();
        $element.click();
        me.selectDate(getConfig("currentCalendar", "gregorian"), $element.val());
    });

    $element.prev().click(function () {
        (config.beforeShow || (() => { }))();
        me.jalali();
        me.gregorian();
        calendarWrapper.show();
        if (getConfig("currentCalendar", "gregorian") == 'jalali') {
            calendarWrapper.find('.jalalicalendar').show();
            calendarWrapper.find('.gregoriancalendar').hide();
        } else {
            calendarWrapper.find('.jalalicalendar').hide();
            calendarWrapper.find('.gregoriancalendar').show();
        }
        me.selectDate(getConfig("currentCalendar", "gregorian"));
        me.controllerArrows();
    });

    $element.click(function () {
        (config.beforeShow || (() => { }))();
        me.jalali();
        me.gregorian();
        calendarWrapper.show();
        if (getConfig("currentCalendar", "gregorian") == 'jalali') {
            calendarWrapper.find('.jalalicalendar').show();
            calendarWrapper.find('.gregoriancalendar').hide();
        } else {
            calendarWrapper.find('.jalalicalendar').hide();
            calendarWrapper.find('.gregoriancalendar').show();
        }
        me.selectDate(getConfig("currentCalendar", "gregorian"));
        me.controllerArrows();

    });

    $('html').click(function (event) {
        if (calendarContainer.has(event.target).length == 0) {
            calendarWrapper.hide();
        };
    });

    this.close = function () {
        calendarWrapper.find('.close-btn').click(function () {
            (config.afterShow || (() => { }))();
            calendarWrapper.hide();
        });
    }

    this.jalali = function () {
        calendarWrapper.find('.jalalicalendar').empty();
        var jalaliCalendar = me.draw('jalali');
        calendarWrapper.find('.jalalicalendar').append(jalaliCalendar);
        me.close();
    }

    this.gregorian = function () {
        calendarWrapper.find('.gregoriancalendar').empty();
        var gregorianCalendar = me.draw('gregorian');
        calendarWrapper.find('.gregoriancalendar').append(gregorianCalendar);
        me.close();
    }

    this.draw = function (calendar) {
        var wrapDate = new WrapDate(calendar);
        var year = wrapDate.getYear();
        var month = wrapDate.getMonth();
        var months = wrapDate.rangeNameMonths();
        var weekdays = wrapDate.rangeNameWeekdays();
        var nextDay = wrapDate.getNextDate('24');
        var threeDayLater = wrapDate.getNextDate('72');
        var customDatepickerWrapper = $(`<div class=""><div class="custom-datepicker-header"><button class="_desk-h close-btn" type="button">&#215;</button><button class="prev datepicker-control _mob-h" type="button"><i class="fa fa-angle-left"></i></button><button class="next datepicker-control _mob-h" type="button"><i class="fa fa-angle-right"></i></button></div><div class="custom-datepicker-months"></div></div>`);
        var datepickerTable = view('partials/datepicker-table', { weekdays: weekdays });
        var currentMonth;
        var targetMonth;
        for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
            var calcMonth = (month + monthIndex) % 12;
            var calcYear = year + Math.floor((month + monthIndex) / 12);
            targetMonth = $(datepickerTable.replace('currentmonth', months[calcMonth] + ' ' + (calcYear)));
            var daysInMonth = wrapDate.daysInMonth(calcYear, (calcMonth + 1));
            var weekday = wrapDate.getDay(calcYear, calcMonth);
            var weeks = [];
            wrapDate.colspanBeforeWeekday(weeks, weekday);

            for (var i = 1; i <= daysInMonth; i++) {
                var position = wrapDate.weekdayPosition(weekday, i);
                weeks[position] = weeks[position] || '';
                weeks[position] += '<td><div class="custom-datepicker-day" data-value="' + calcYear + '-' + ((calcMonth < 9) ? ('0' + (calcMonth + 1)) : (calcMonth + 1)) + '-' + (i < 10 ? ('0' + i) : i) + '">' + i + '</div></td>';
            }
            targetMonth.find('.days').append('<tr>' + weeks.join('</tr><tr>') + '</tr>');
            targetMonth.data('month', calcMonth);
            customDatepickerWrapper.find('.custom-datepicker-months').append(targetMonth);
            if ($element.val() != '') {
                currentMonth = wrapDate.getCurrentMonth($element.val());
                if (targetMonth.data('month') == currentMonth) {
                    targetMonth.addClass('active');
                }
            }
        }
        if ($element.val() == '') {
            if ($element.hasClass('end-date')) {
                if (startDate.val() != '') {
                    let startDateMonth = wrapDate.getCurrentMonth(startDate.val());
                    customDatepickerWrapper.find('.custom-datepicker-month').each((monthIndex, monthSection) => {
                        if ($(monthSection).data('month') == startDateMonth) {
                            $(monthSection).addClass('active');
                        }
                        $element.val(startDate.val());
                        $element.next().next().val(startDate.val());
                    });
                } else {
                    customDatepickerWrapper.find('.custom-datepicker-months>:first-child').addClass('active');
                }
            } else {
                customDatepickerWrapper.find('.custom-datepicker-months>:first-child').addClass('active');
            }
        }
        return customDatepickerWrapper;
    }

    this.selectDate = function (calendar, selectedDate) {
        var wrapDate = new WrapDate(calendar);
        var currentDate;
        if (selectedDate == undefined) {
            currentDate = wrapDate.getCurrentDate();
        } else {
            currentDate = selectedDate;
        }
        var datePickerDays = calendarContainer.find('.custom-datepicker-day');
        datePickerDays.each((index, item) => {
            var $item = $(item);
            if ($element.val() == '') {
                if ($item.data('value') == currentDate) {
                    $item.addClass('selectedDate');
                }
                me.convertDate(calendar, currentDate);
                $element.val(currentDate);
            } else {
                if ($item.data('value') == $element.val()) {
                    $item.addClass('selectedDate');
                    me.convertDate(calendar, $element.val());
                }
            }
            if ($item.data('value') < currentDate) {
                $item.addClass('day-disabled');
            }
            if ($element.hasClass('end-date')) {
                if ($item.data('value') < startDate.val()) {
                    $item.addClass('day-disabled');
                }
            }
        });

        datePickerDays.click(function (e) {
            if ($(this).hasClass('day-disabled')) {
                return;
            } else {
                calendarWrapper.find('.selectedDate').removeClass('selectedDate');
                $(this).addClass('selectedDate');
                $element.val($(this).data('value'));
                me.convertDate(calendar, $(this).data('value'));
                (config.afterShow || (() => { }))();
                $element.change();
                calendarWrapper.hide();
                if($element.closest('.start-flight-date').data('triptype') == 'openReturn') {
                    $element.closest('.start-flight-date').next().find('input.end-date').val(getNextMonth($(this).data('value'),false, $element.next().next().val()));
                    $element.closest('.start-flight-date').next().find('input.return').val(getNextMonth($(this).data('value'),true, $element.next().next().val()));
                }
            }
        });
    }

    this.controllerArrows = function () {
        var activeCalendar = calendarWrapper.find('.' + getConfig("currentCalendar") + 'calendar');
        var nextBtn = activeCalendar.find('.next');
        var prevBtn = activeCalendar.find('.prev');
        if (activeCalendar.find('.active').next().length == 0) {
            nextBtn.prop("disabled", true);
        }

        if (activeCalendar.find('.active').prev().length == 0) {
            prevBtn.prop("disabled", true);
        }
        nextBtn.click(function () {
            if (prevBtn.prop("disabled") == true) {
                prevBtn.prop("disabled", false);
            }
            if (activeCalendar.find('.active').next().length != 0) {
                activeCalendar.find('.active').removeClass('active').next().addClass('active');
            }
            if (activeCalendar.find('.active').next().length == 0) {
                nextBtn.prop("disabled", true);
            }
        });
        prevBtn.click(function () {
            if (nextBtn.prop("disabled") == true) {
                nextBtn.prop("disabled", false);
            }
            if (activeCalendar.find('.active').prev().length != 0) {
                activeCalendar.find('.active').removeClass('active').prev().addClass('active');
            }
            if (activeCalendar.find('.active').prev().length == 0) {
                prevBtn.prop("disabled", true);
            }
        });
    }

    this.convertDate = function (calendar, elementValue) {
        if (calendar == 'jalali') {
            elementValue = elementValue.split('-');
            $element.next().next().val(dayjs((new persianDate([parseInt(elementValue[0]), parseInt(elementValue[1]), parseInt(elementValue[2])])).toDate()).format("YYYY-MM-DD"));
        } else {
            $element.next().next().val(elementValue);
        }
    }

    /// init
    if (config.dateRange == true) {
        var wrapDate = new WrapDate(currentCalendar);
        var nextDay = wrapDate.getNextDate('24');
        var threeDayLater = wrapDate.getNextDate('72');
        if ($element.hasClass('start-date') && $element.val() == '') {
            me.convertDate(currentCalendar, nextDay);
            $element.val(nextDay);
        }
        if ($element.hasClass('end-date') && $element.val() == '') {
            me.convertDate(currentCalendar, threeDayLater);
            $element.val(threeDayLater);
        }
    }

    function getNextMonth (shamsiDate,gregorian,gregorianDate) {
        if(gregorian) {
            var initialDate = new Date(gregorianDate);
            var newDate = new Date(initialDate);
            newDate.setDate(initialDate.getDate() + 30);
            var newDateFormatted = newDate.toISOString().split('T')[0];
            return newDateFormatted;
        }
        else {
            const newShamsiDate = shamsiDate.split('-').map(d => Number(d));
            const thirtyDaysLater = new persianDate(newShamsiDate).add('days' , 30);
            const converting = thirtyDaysLater.year() + '-' + thirtyDaysLater.month() + '-' + thirtyDaysLater.date();
            return converting
        }
    }
}

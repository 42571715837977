function ParsePhoneNumber(selector, config) {
    var $element = $(selector);
    var nodeCountry = getConfig('city').country_id;
    var me = this;
    switch ($element.length) {
        case 0:
            return;
        case 1:
            break;
        default:
            $element.each((index, item) => {
                new ParsePhoneNumber(item, config);
            });
            return;
    }

    config = config || {};
    $element.hide();
    var name = $element.attr('name');
    $element.removeAttr('name');
    const phoneNumber = $element.val().split('-');
    var $container = $(view('partials/parse-phone-number', { name, codes: config.codes, country: nodeCountry, phoneNumber }));
    $element.after($container).appendTo($container);
}
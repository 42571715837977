function convertPersianNumberToEnglish(number) {
    let englishNumber = ''
    const p2e = { "۰": 0, "۱": 1, "۲": 2, "۳": 3, "۴": 4, "۵": 5, "۶": 6, "۷": 7, "۸": 8, "۹": 9 }
    for (let num of number) englishNumber += p2e[num]
    return englishNumber
}

function convertPersionDateToEnglish(persianDate) {
    // Remove spaces and parentheses
    persianDate = persianDate.replace(/\s/g, '')
        .replace('(', '-')
        .replace(')', '');
    // Convert Persion digits to English
    persianDate = persianDate
        .replace(/۰/g, '0')
        .replace(/۱/g, '1')
        .replace(/۲/g, '2')
        .replace(/۳/g, '3')
        .replace(/۴/g, '4')
        .replace(/۵/g, '5')
        .replace(/۶/g, '6')
        .replace(/۷/g, '7')
        .replace(/۸/g, '8')
        .replace(/۹/g, '9');
    return persianDate;
}
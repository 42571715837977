function queueable(selector) {
    if (!(queueable.isRun || false)) {
        $.fn.queueable = function () {
            var me = this[0];
            var $me = this;
            this.data('queue', []);
            this.push = function (item) {
                $me.data('queue').push(item);
                me.isBusy = true;
            };
            this.pop = function (item) {
                var queue = $me.data('queue');
                queue.splice(queue.indexOf(item), 1);
                me.isBusy = queue.length != 0;
            };
            this.isBusy = () => me.isBusy;
        }
        queueable.isRun = true;
    }
    var $element = $(selector);
    $element.queueable();

    return $element;
}